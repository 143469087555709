import React, {Fragment} from 'react';
import {Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Dimmer, Loader} from 'semantic-ui-react';
import {AppContext} from '../../store';
import NavBar from '../../components/header/NavBar';
import MobileHeader from '../../components/mobile/MobileHeader';
import FloatingChat from '../../components/chat/FloatingChat';
import LiveSupportBtn from '../../components/shared/LiveSupportBtn';
import LoaderPage from '../../ui/LoaderPage';
import SpeakerModal from '../../components/modals/modalsUrl/SpeakerModal';
import SessionModal from '../../components/modals/modalsUrl/SessionModal';
import PartnerModal from '../../components/modals/modalsUrl/PartnerModal';
import AttendeeModal from '../../components/modals/modalsUrl/AttendeeModal';
import InvitationModalPage from '../../components/modals/modalsMobilePage/InvitationModalPage';
import SessionModalPage from '../../components/modals/modalsMobilePage/SessionModalPage';
import AttendeeModalPage from '../../components/modals/modalsMobilePage/AttendeeModalPage';
import PartnerModalPage from '../../components/modals/modalsMobilePage/PartnerModalPage';
import SpeakerModalPage from '../../components/modals/modalsMobilePage/SpeakerModalPage';
import ModalRoute from '../ModalRoute';
import './MainLayout.scss';

const EventLayout = React.lazy(() => import('../EventLayout'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const EventList = React.lazy(() => import('../../pages/EventList'));
const Invitations = React.lazy(() => import('../../pages/Invitations'));
const Account = React.lazy(() => import('../../pages/Account'));
const Chat = React.lazy(() => import('../../pages/Chat'));
const MyAgenda = React.lazy(() => import('../../pages/MyAgenda'));
const Notifications = React.lazy(() => import('../../pages/Notifications'));
const ScannedContacts = React.lazy(() => import('../../pages/ScannedContacts'));

const MainLayout = observer(() => {
  const {userStore, chatStore, activityStore, eventStore, agendaStore, invitationStore, windowSizeStore} =
    React.useContext(AppContext);
  const {user} = userStore;
  const {eventList, loadedActiveEvents, activeEvents} = eventStore;
  const isMobile = windowSizeStore.windowSizes === 'mobile';
  const location = useLocation();
  const history = useHistory();
  const background = location.state && location.state.background;
  const [loading, setLoading] = React.useState(false);
  const siteName = window.location.hostname;
  const siteFirstName = siteName.split('.')[0];

  React.useEffect(() => {
    if (userStore.isUserLogged && eventList) {
      chatStore.loadChatRooms(user.id);
    }
  }, [eventList]);

  React.useEffect(() => {
    if (loadedActiveEvents) {
      if (!location.pathname.includes('invitations')) {
        if (activeEvents.length) {
          invitationStore.loadInvitationsForActiveEvents();
        } else {
          invitationStore.loadInvitationsForPatsEvents();
          agendaStore.data.loadAgendaForPatsEvents();
        }
      }
      agendaStore.data.loadAgendaForActiveEvents();
    }
  }, [loadedActiveEvents]);

  React.useEffect(() => {
    if (eventList && loadedActiveEvents) {
      activityStore.loadNotifications();
    }
  }, [eventList, loadedActiveEvents, activityStore]);

  if (
    process.env.NODE_ENV === 'production' &&
    location.pathname === '/' &&
    !loading &&
    !['qa', 'letsgo', 'dealroomevent-stage'].includes(siteFirstName)
  ) {
    setLoading(true);
    eventStore
      .loadDomainDetails()
      .then(domainDetails => {
        if (domainDetails.publicEvent) {
          return eventStore
            .getEventDetails(domainDetails.id)
            .then(() => {
              setLoading(false);
              return history.push(`/event/${domainDetails.id}/` + domainDetails.indexPagePath);
            })
            .catch(() => {
              history.push(`/event/list`);
              setLoading(false);
            });
        }
        history.push(`/event/list`);
        return setLoading(false);
      })
      .catch(() => {
        history.push(`/event/list`);
        setLoading(false);
      });
  }

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader size="medium" inverted />
      </Dimmer>
    );
  }

  const mainRoute = () => {
    if (!eventList) {
      return (
        <Dimmer active inverted>
          <Loader size="medium" inverted />
        </Dimmer>
      );
    }
    return (
      <React.Suspense fallback={<LoaderPage />}>
        <Switch location={background || location}>
          <Redirect exact from="/" to="/event/list" />
          <Route exact path="/event/list" component={EventList} />
          <Route path="/event/:eventId/" component={EventLayout} />
          <Route path="/invitations" component={Invitations} />
          <Route path="/my-agenda" component={MyAgenda} />
          <Route exact path="/account/:type(profile|notifications|security|connected-emails)" component={Account} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/contacts" component={ScannedContacts} />
          <Route exact path="/notifications" component={Notifications} />

          <Route path="/event/:eventId/speakers/:speakerId" component={isMobile ? SpeakerModalPage : SpeakerModal} />
          <Route path="/event/:eventId/sessions/:sessionId" component={isMobile ? SessionModalPage : SessionModal} />
          <Route path="/event/:eventId/expo/:partnerId" component={isMobile ? PartnerModalPage : PartnerModal} />
          <Route
            path="/event/:eventId/attendees/:attendeeId"
            component={isMobile ? AttendeeModalPage : AttendeeModal}
          />
          <Route path="/event/:eventId/invitation/:attendeeId" component={InvitationModalPage} />
          <Route
            path="/event/:eventId/invitation/:attendeeId/decline"
            render={() => <InvitationModalPage action="decline" />}
          />
          <Route
            path="/event/:eventId/invitation/:attendeeId/accept"
            render={() => <InvitationModalPage action="accept" />}
          />
          <Route path="/my-agenda/session/:eventId/:sessionId" render={() => <SessionModalPage />} />
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    );
  };

  return (
    <Fragment>
      <div className="main">
        {location.pathname.indexOf("/badges")>=0?"":(isMobile ? <MobileHeader /> : <NavBar />)}
        <div className="main__wrapper">{mainRoute()}</div>
        <ModalRoute background={background} location={location} isMobile={isMobile} />
      </div>
      {location.pathname !== '/chat' ? <FloatingChat /> : null}
      {!localStorage.getItem('appNative') ? <LiveSupportBtn /> : null}
    </Fragment>
  );
});

export default MainLayout;
